import React from "react";
import HeroImage from "../assets/heroImage.png";
import { HiArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-scroll";

const Home = () => {
  return (
    <div
      name="Acceuil"
      className="h-screen w-full bg-gradient-to-b from-black via-black  to-gray-800 "
    >
      <div className="pt-10 py-4 max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row ">
        <div className="flex flex-col justify-center h-[90%]">
          <h2 className="text-4xl sm:text-7xl font-bold text-white">
            Développeur Web Full Stack
          </h2>
          <p className="text-gray-500 py-4 max-w-md">
            J'ai 3 ans d'expérience dans la création et la conception de
            d'applications. Actuellement, j'aime travailler sur des applications
            web et mobile utilisant des technologies telles que PHP, Symfony,
            Javascript, Flutter.
          </p>
          <div>
            <Link
              to="Réalisations"
              smooth
              duration={500}
              className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer"
            >
              Réalisations
              <span className="group-hover:rotate-90 duration-300">
                <HiArrowNarrowRight size={20} className="ml-3" />
              </span>
            </Link>
          </div>
        </div>
        <div>
          <img
            src={HeroImage}
            alt="my profile"
            className="mb-20 rounded-2xl mx-auto w-2/3 md:w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default Home;

import React from "react";
import lbled from "../assets/portfolio/lbled.png";

function Portfolio() {
  const portfolios = [
    {
      id: 1,
      src: lbled,
    },
  ];

  return (
    <div
      name="Réalisations"
      className="bg-gradient-to-b from-black to-gray-800 w-full text-white md:h-screen"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full ">
        <div className="mt-20">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            Réalisations
          </p>
          <p className="py-6">Découvrez certaines de mes réalisations ici</p>
        </div>

        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-12 sm:px-0">
          {portfolios.map(({ id, src }) => (
            <div key={id} className="shadow-md shadow-gray-600 rounded-lg">
              <img
                src={src}
                alt=""
                className="rounded-md duration-200 hover:scale-105"
              />
              <div className="flex items-center justify-center">
                <button className="w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105">
                  <a
                    href="https://www.lbled.eu"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Consulter
                  </a>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Portfolio;

import React, { useEffect } from "react";
// import { Helmet } from "react-helmet";
import About from "./components/About";
import Contact from "./components/Contact";
import Experience from "./components/Experience";
import Home from "./components/Home";
import NavBar from "./components/NavBar";
import Portfolio from "./components/Portfolio";
import SocialLinks from "./components/SocialLinks";

function App() {
  useEffect(() => {
    // Mettre à jour le titre de l'onglet lorsque le composant est monté
    document.title = "Soufiane KABDANI";
  }, []);

  return (
    <div>
      <head>
        <link rel="icon" href="%PUBLIC_URL%/assets/SK.png" />
      </head>
      <NavBar />
      <Home />
      <About />
      <Portfolio />
      <Experience />
      <Contact />
      <SocialLinks />
    </div>
  );
}

export default App;

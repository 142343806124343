import React from "react";

function About() {
  return (
    <div
      name="Descritpion"
      className="w-full min-h-screen md:h-screen bg-gradient-to-b from-gray-800 to-black text-white"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="mt-20">
          <p className=" text-4xl font-bold inline border-b-4 border-gray-500">
           Descritpion
          </p>
        </div>
        <p className="text-xl mt-10 ">
          En tant que Concepteur développeur d'applications, je suis passionné
          par la création de solutions numériques innovantes. Fort d'un Bachelor
          en Développement Web obtenu après trois années d'études approfondies,
          j'ai acquis une solide base technique et une compréhension approfondie
          des technologies web.
        </p>

        <br />

        <p className="text-xl">
          Au cours de mes projets académiques et professionnels, j'ai acquis une
          expérience pratique dans la conception et le développement
          d'applications web réactives, interactives et axées sur l'utilisateur.
          Ma curiosité naturelle me pousse à rester constamment à jour avec les
          dernières tendances technologiques, me permettant ainsi d'intégrer des
          solutions novatrices dans mes projets.
        </p>
        <br />

        <p className="text-xl ">
          En tant que professionnel déterminé, je suis prêt à relever de
          nouveaux défis et à collaborer avec des équipes passionnées pour créer
          des applications qui dépassent les attentes. Ma philosophie de travail
          repose sur l'amélioration continue, la résolution créative de
          problèmes et l'engagement envers la qualité du code.
        </p>
      </div>
    </div>
  );
}

export default About;
